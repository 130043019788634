import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const Article = ({ post, title, featuredImage }) => {
  return (
    <li className="w-full lg:w-1/3 p-2.5 mb-24" key={post.uri}>
      <article itemScope itemType="http://schema.org/Article">
        <header className="py-5">
          <Link to={post.uri} itemProp="url">
            {/* if we have a featured image for this post let's display it */}
            {featuredImage?.data && (
              <GatsbyImage
                image={featuredImage.data}
                alt={featuredImage.alt}
                className="mb-4"
              />
            )}
            <h2
              className="block text-black font-bold text-xl text-left pb-3 lg:pr-20"
              itemProp="headline"
            >
              {parse(title)}
            </h2>
          </Link>

          <small className="font-thin italic text-lg">{post.date}</small>
        </header>
        <section className="block text-xl font-light" itemProp="description">
          {parse(post.excerpt)}
        </section>
        <Link
          className="block text-black font-light text-left text-xl py-5 underline italic"
          to={post.uri}
        >
          → DEVAMINI GÖR
        </Link>
      </article>
    </li>
  )
}

export default Article
