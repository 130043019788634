import React from "react"
import { Link } from "gatsby"

const Pagination = ({
  totalPages,
  currentPagePath,
  previousPagePath,
  nextPagePath,
  rootSlug
}) => {
  const pageNumbers = []

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i)
  }

  const pageSelector = currentPagePath.slice(-1)[0]

  return (
    <nav className="-mt-14 mb-20">
      <ul className="flex justify-center">
        {previousPagePath && (
          <>
            <Link className="py-2 px-4" to={previousPagePath}>
              ← Önceki
            </Link>
          </>
        )}
        {/* "border rounded border-blue" */}
        {pageNumbers.map(number => (
          <Link
            className={`py-2 px-4 rounded hover:underline ${
              number === 1 && !previousPagePath ? "border border-blue" : ""
            }  ${pageSelector === String(number) ? "border border-blue" : ""} `}
            key={number}
            to={number === 1 ? rootSlug : `${rootSlug}/${number}`}
          >
            {number}
          </Link>
        ))}
        {nextPagePath && (
          <Link className="py-2 px-4" to={nextPagePath}>
            Sonraki →
          </Link>
        )}
      </ul>
    </nav>
  )
}

export default Pagination
